
















import { Component, Vue } from 'vue-property-decorator'
import NavConfigs from './config'

import NavBarItem from './NavBarItem.vue'

@Component({
  components: {
    NavBarItem
  }
})
export default class NavBar extends Vue {
  public navConfigs = NavConfigs

  get defaultActive () {
    return this.$route.meta ? this.$route.meta.navIndex || '' : ''
  }
}
