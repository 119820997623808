




import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class LogoComponent extends Vue {
  @Prop({ default: '' }) private title!: string;
}
