


















import NavBarItem from './NavBarItem.vue'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    NavBarItem
  }
})
export default class NavBarItemComponent extends Vue {
  @Prop({ default: () => {} }) private submenu!: any
}
