
































import { Component, Vue } from 'vue-property-decorator'

@Component
export default class HeaderNav extends Vue {
  get defaultActive () {
    return this.$route.meta ? this.$route.meta.subNavIndex || '' : ''
  }
}
