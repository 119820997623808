







import { Component, Vue } from 'vue-property-decorator'
import { UserModule } from '@/store/modules/user'

@Component
export default class LayoutHeaderComponent extends Vue {
  get userName () {
    return UserModule.userName
  }

  logout () {
    UserModule.logout()
    this.$message.warning('已登出!')
    this.$router.push('/login')
  }
}
